// insert here global constants

import { ClipIntervalType, OrderType } from './interfaces'

export const SERVICE_ROOT_URL =
  process.env.NODE_ENV !== 'test'
    ? process.env.NEXT_PUBLIC_ESG_LAB_SERVICE_ROOT === undefined
      ? process.env.STORYBOOK_ESG_LAB_SERVICE_ROOT
      : process.env.NEXT_PUBLIC_ESG_LAB_SERVICE_ROOT
    : 'http://localhost:3000'

export const API_URLS = {
  login: '/api/auth',
  logout: '/api/auth/logout',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export const OrderTypeLabels: Record<OrderType, string> = {
  market: 'Market',
  limit: 'Limit',
  'stop-limit': 'Stop Limit',
  'steady-pace': 'Steady Pace',
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const ClipIntervalLabels: Record<ClipIntervalType, string> = {
  seconds: 'SEC',
  minutes: 'MIN',
  hours: 'H',
}
