import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

import fonts from './fonts'

export const GlobalCustomStyles = createGlobalStyle`
  body {
    ${tw`antialiased`}
  }

  svg {
    width: 24px;
    fill: black;
  }

  ${fonts}
`
export default GlobalCustomStyles
