import { css } from 'styled-components'

const fonts = css`
  @font-face {
    font-family: 'Inter';
    src: local('Inter'),
      url('/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url('/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'),
      url('/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url('/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'),
      url('/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
  }

  // Bahnschrift

  @font-face {
    font-family: 'Bahnschrift';
    src: local('Bahnschrift'),
      url('/fonts/Bahnschrift/Bahnschrift.ttf') format('truetype');
  }
`

export default fonts
